<template>
  <FormRulesProvider :module-name="$constants.TASK">
    <div class="flex flex-col flex-1">
      <Component
        :is="stickySearch ? 'MAffix' : 'div'"
        :offset-top="offsetTop"
        @change="searchAffixed = $event"
      >
        <div :class="{ 'pt-2': searchAffixed }" class="sticky-tab-header">
          <MRow class="mb-4">
            <MCol>
              <Searchbox v-model="filters" @change="refreshList" />
            </MCol>
            <MCol v-if="!disabled" class="m-align-button">
              <MButton
                id="add-task-btn"
                variant="neutral"
                @click="handleCreate"
              >
                {{ $t('add') }} {{ $tc('task') }}
              </MButton>
            </MCol>
          </MRow>
          <MDivider class="mb-3" />
        </div>
      </Component>
      <MCollapse
        v-if="totalStages > 0"
        class="mb-4 flex-1 collapse-menu"
        default-active-key="service_catalog_task"
      >
        <MCollapsePanel key="service_catalog_task">
          <template v-slot:header>
            <h5 class="text-ellipsis m-0 pb-1 flex">
              {{ $t('service_catalog') }} {{ $tc('task', 2) }}
              <small class="ml-auto mr-2">
                {{ $tc('stage', totalStages) }}:
                <span> {{ activeStageCountRenderer }}/{{ totalStages }} </span>
              </small>
            </h5>
          </template>
          <TaskCrud
            ref="stageCrudContainer"
            :filters="filters"
            :total-stages="totalStages"
            :active-stage="activeStage"
            :stages="stages"
            :resource-id="resourceId"
            :module-name="moduleName"
            :disabled="disabled"
            has-stages
            @edit="handleEdit"
            @refresh="refreshTicket"
            @refresh-stages="getTaskStages"
          />
        </MCollapsePanel>
      </MCollapse>
      <TaskCrud
        ref="manualCrudContainer"
        :resource-id="resourceId"
        :filters="filters"
        :module-name="moduleName"
        :disabled="disabled"
        :show-no-data="false"
        @edit="handleEdit"
        @refresh="refreshTicket"
      />
    </div>
  </FormRulesProvider>
</template>

<script>
import FormRulesProvider from '@components/providers/form-rules-provider/form-rules-provider'
import { getDetailLinkForModule } from '@utils/get-module-detail-link'
import { getTaskStagesApi } from './api'
import Searchbox from './task-search-box'
import TaskCrud from './task-crud'

export default {
  name: 'TaskContainer',
  components: { Searchbox, TaskCrud, FormRulesProvider },
  props: {
    resourceId: { type: Number, required: true },
    moduleName: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    hasStages: { type: Boolean, default: false },
    stickySearch: { type: Boolean, default: false },
    offsetTop: { type: Number, default: 0 },
  },
  data() {
    return {
      totalStages: null,
      stages: [],
      activeStage: null,
      filters: {
        sortBy: 'createdTime',
      },
      searchAffixed: false,
    }
  },
  computed: {
    activeStageCountRenderer() {
      return this.activeStage > this.totalStages
        ? this.totalStages
        : this.activeStage
    },
  },
  created() {
    if (this.hasStages) {
      this.getTaskStages()
    }
  },
  methods: {
    getTaskStages() {
      getTaskStagesApi(this.moduleName, this.resourceId).then((data) => {
        this.totalStages = data.total
        this.stages = data.stages
        this.activeStage = data.activeStage
      })
    },
    refreshList() {
      if (this.$refs.stageCrudContainer) {
        this.$refs.stageCrudContainer.refresh()
      }
      this.$refs.manualCrudContainer.refresh()
    },
    handleCreate() {
      const link = getDetailLinkForModule(
        this.moduleName,
        this.resourceId,
        this.$modules,
        this.moduleName,
        { activeTab: 'task' }
      )
      this.$router.push(
        this.$modules.getModuleRoute('my-tasks', 'create', {
          query: {
            refModel: this.moduleName,
            refId: this.resourceId,
            backLink: JSON.stringify(link.link),
          },
        })
      )
      // this.$refs.manualCrudContainer.handleCreate()
    },
    handleEdit(task) {
      const link = getDetailLinkForModule(
        this.moduleName,
        this.resourceId,
        this.$modules,
        this.moduleName,
        { activeTab: 'task' }
      )
      this.$router.push(
        this.$modules.getModuleRoute('my-tasks', 'edit', {
          params: { id: task.id },
          query: {
            refModel: this.moduleName,
            refId: this.resourceId,
            backLink: JSON.stringify(link.link),
          },
        })
      )
    },
    refreshTicket() {
      this.$emit('refresh')
    },
  },
}
</script>
