<template>
  <span>
    <template v-if="column.fieldType === 'datetime'">
      {{ item[column.key] | datetime }}
    </template>
    <template v-else-if="column.fieldType === 'dependent'">
      <CustomAsSystemPicker
        v-if="(column.field || {}).fieldResolutionType === 'system_field'"
        disabled
        text-only
        :as-input="false"
        :value="item[column.key]"
        :module-name="moduleName"
        placeholder="---"
        :field-reference-type="(column.field || {}).fieldReferenceType"
      />
      <span v-else>
        {{ getDependentFieldValue(column) }}
      </span>
    </template>
    <template
      v-else-if="
        column.fieldType === 'dropdown' &&
        (column.field || {}).fieldResolutionType === 'system_field'
      "
    >
      <CustomAsSystemPicker
        text-only
        disabled
        :as-input="false"
        :value="item[column.key]"
        :module-name="moduleName"
        placeholder="---"
        :field-reference-type="(column.field || {}).fieldReferenceType"
      />
    </template>
    <template v-else>
      {{ item[column.key] }}
    </template>
  </span>
</template>

<script>
import CustomAsSystemPicker from '@modules/form/components/fields/custom-as-system.vue'
import { flattenRecursive } from '@data/recursive'
export default {
  name: 'ListTableCustomFieldValue',
  components: { CustomAsSystemPicker },
  props: {
    moduleName: { type: String, required: true },
    column: { type: Object, required: true },
    item: { type: Object, required: true },
  },
  methods: {
    getDependentFieldValue(column) {
      const flattenedTree = flattenRecursive(column.tree)
      return (flattenedTree.find((o) => o.id === this.item[column.key]) || {})
        .name
    },
  },
}
</script>
