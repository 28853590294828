<template>
  <TicketList
    :fetch-fn="getRequests"
    :module-name="$constants.REQUEST"
    scrollable
  />
</template>

<script>
import { getRequestsForUserApi } from '@modules/ticket/ticket-api'
import TicketList from '@components/item-selection-list/ticket-list'

export default {
  name: 'RecentRequests',
  components: { TicketList },
  props: {
    user: { type: Object, required: true },
  },
  methods: {
    getRequests(selectedSearch, criterias, limit, offset) {
      return getRequestsForUserApi(this.user.id, limit, offset)
    },
  },
}
</script>
