<template>
  <FlotoCrudContainer
    ref="crudContainerRef"
    :fetch-fn="getWorkLogs"
    :update-fn="updateWorkLog"
    :scrollable="false"
    :create-fn="addWorkLog"
    :delete-fn="removeWorkLog"
    :default-item="defaultWorklog"
    :columns="tableColumns"
    :show-no-data="false"
    as-table
  >
    <template v-slot:add-controls="{ create, refreshList }">
      <Component
        :is="stickySearch ? 'MAffix' : 'div'"
        v-if="!disabled"
        :offset-top="offsetTop"
        @change="searchAffixed = $event"
      >
        <div
          v-if="isAllowedWorklogAddAccess"
          :class="{ 'pt-2': searchAffixed }"
          class="sticky-tab-header"
        >
          <MRow>
            <MCol class="m-align-button">
              <slot name="before-add-controls"></slot>
              <MTooltip>
                <template v-slot:trigger>
                  <MButton
                    shape="circle"
                    variant="neutral-lighter"
                    :shadow="false"
                    class="mr-2"
                    @click="refreshList"
                  >
                    <MIcon name="sync" />
                  </MButton>
                </template>
                {{ $t('refresh') }}
              </MTooltip>
              <MButton id="add-worklog-btn" variant="neutral" @click="create">
                {{ $t('add') }} {{ $tc('work_log') }}
              </MButton>
            </MCol>
          </MRow>
          <MDivider />
        </div>
      </Component>
    </template>
    <template v-slot:form-header="{ item }">
      {{ $t(item.id ? 'edit' : 'add') }} {{ $tc('work_log') }}
    </template>
    <template v-slot:form-items="{ item: worklog, resetForm }">
      <WorkLogForm
        :worklog="worklog"
        :group-id="groupId"
        :resource="resource"
        :technician="user"
        :custom-rules="customRules"
        :reset-form="resetForm"
      />
    </template>
    <template v-slot:form-actions="{ submit, cancel, item, processing }">
      <MButton
        id="add-update-btn"
        class="mx-1"
        :loading="processing"
        @click="submit"
      >
        {{
          $t(item.id ? 'update' : item.timeBasedWorkLog ? 'start_timer' : 'add')
        }}
      </MButton>
      <MButton id="cancel-btn" variant="default" @click="cancel">
        {{ $t('cancel') }}
      </MButton>
    </template>
    <template v-slot:technicianId="{ item }">
      <td>
        <FlotoTechnicianPicker
          :value="item.technicianId"
          class="cursor-default"
          disabled
          placeholder="---"
        />
      </td>
    </template>
    <template v-slot:startDate="{ item }">
      <td class="text-ellipsis">{{ item.timeRange.startTime | datetime }}</td>
    </template>
    <template v-slot:endDate="{ item }">
      <td class="text-ellipsis">
        {{
          !item.timeRange.endTime ? '---' : item.timeRange.endTime | datetime
        }}
      </td>
    </template>
    <template v-slot:timeTaken="{ item }">
      <td class="text-ellipsis">
        <FlotoDot
          v-if="item.workLogState === 'start' || item.workLogState === 'pause'"
          class="mx-1"
          :bg="
            item.workLogState === 'start'
              ? colors.success
              : colors['neutral-light']
          "
        />
        {{ calculateTimeTaken(item) }}
      </td>
    </template>
    <template v-slot:description="{ item }">
      <MTooltip :disabled="item.description.length <= 20">
        <template v-slot:trigger>
          <td class="text-ellipsis">{{ item.description }}</td>
        </template>
        {{ item.description }}
      </MTooltip>
    </template>
    <template v-slot:actions="{ item, edit, remove, update }">
      <td>
        <div
          v-if="isUpdateable(item) || item.technicianId === user.id"
          class="flex"
        >
          <template v-if="item.timeBasedWorkLog">
            <FlotoDeleteBtn
              v-if="
                item.workLogState === 'start' || item.workLogState === 'pause'
              "
              class="mx-1"
              :message="$t('confirm_stop_item', { item: $t('timer') })"
              @confirm="update({ ...item, workLogState: 'stop' })"
            >
              <template v-slot:trigger>
                <a href="javascript:;">
                  <MTooltip>
                    <template v-slot:trigger>
                      <MIcon name="stop" />
                    </template>
                    {{ $t('stop') }}
                  </MTooltip>
                </a>
              </template>
            </FlotoDeleteBtn>
            <FlotoDeleteBtn
              v-if="item.workLogState === 'pause'"
              class="mx-1"
              :message="$t('confirm_start_item', { item: $t('timer') })"
              @confirm="update({ ...item, workLogState: 'start' })"
            >
              <template v-slot:trigger>
                <a href="javascript:;">
                  <MTooltip>
                    <template v-slot:trigger>
                      <MIcon name="play" />
                    </template>
                    {{ $t('start') }}
                  </MTooltip>
                </a>
              </template>
            </FlotoDeleteBtn>
            <FlotoDeleteBtn
              v-if="item.workLogState === 'start'"
              class="mx-1"
              :message="$t('confirm_pause_item', { item: $t('timer') })"
              @confirm="update({ ...item, workLogState: 'pause' })"
            >
              <template v-slot:trigger>
                <a href="javascript:;">
                  <MTooltip>
                    <template v-slot:trigger>
                      <MIcon name="pause" />
                    </template>
                    {{ $t('pause') }}
                  </MTooltip>
                </a>
              </template>
            </FlotoDeleteBtn>
          </template>
          <MTooltip>
            <template v-slot:trigger>
              <a class="text-neutral" @click="edit">
                <MIcon name="pencil" class="mx-1" />
              </a>
            </template>
            {{ $t('edit') }}
          </MTooltip>
          <FlotoDeleteBtn
            class="mx-1"
            :message="$t('confirm_remove_item', { item: $t('work_log') })"
            @confirm="remove"
          />
        </div>
        <div v-else>---</div>
      </td>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import Moment from 'moment'
import Bus from '@utils/emitter'
import { colors } from '@utils/color'
import { authComputed } from '@state/modules/auth'
import { TechnicianComputed } from '@state/modules/technician'
import WorkLogForm from './worklog-form'
import {
  getWorklogApi,
  getProjectWorklogApi,
  createWorklogApi,
  updateWorklogApi,
  deleteWorklogApi,
} from './api'
import ActionMenu from './action-menu.vue'

export default {
  name: 'TimeLogContainer',
  components: { WorkLogForm, ActionMenu },
  props: {
    disabled: { type: Boolean, default: false },
    resourceId: { type: Number, required: true },
    groupId: { type: Number, default: 0 },
    moduleName: { type: String, required: true },
    stickySearch: { type: Boolean, default: false },
    offsetTop: { type: Number, default: 0 },
    customRules: {
      type: Object,
      default() {
        return {}
      },
    },
    resource: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    this.colors = colors
    return {
      searchAffixed: false,
    }
  },
  computed: {
    ...authComputed,
    ...TechnicianComputed,
    tableColumns() {
      return [
        { name: `${this.$tc('technician')}`, key: 'technicianId' },
        { name: `${this.$t('start')} ${this.$t('date')}`, key: 'startDate' },
        { name: `${this.$t('end')} ${this.$t('date')}`, key: 'endDate' },
        { name: `${this.$t('time_taken')}`, key: 'timeTaken' },
        { name: `${this.$t('description')}`, key: 'description' },
        ...(this.disabled
          ? []
          : [{ name: this.$tc('action', 2), key: 'actions', width: '10rem' }]),
      ]
    },
    defaultWorklog() {
      return {
        technicianId:
          this.customRules.allowedWorklogAccess === 'technicianId'
            ? this.resource.technicianId
            : this.user.id,
        timeRange: {
          startTime: Moment().subtract(3, 'hours').unix() * 1000,
          endTime: Moment().unix() * 1000,
        },
        timeBasedWorkLog: false,
      }
    },
    isOnlyAssigneeWorklogAccess() {
      if (
        (this.customRules || {}).allowedWorklogAccess === 'technicianId' &&
        this.resource.technicianId > 0 &&
        this.resource.technicianId === this.user.id
      ) {
        return true
      }
      return false
    },
    isOnlyTechnicianGroupWorklogAccess() {
      if (
        (this.customRules || {}).allowedWorklogAccess === 'groupId' &&
        this.resource.groupId > 0 &&
        (this.user.groups || []).indexOf(this.resource.groupId) >= 0
      ) {
        return true
      }
      return false
    },
    isAllTechnicianWorklogAccess() {
      if ((this.customRules || {}).allowedWorklogAccess === 'all_technician') {
        return true
      }
      return false
    },
    isAssigneeOrTechnicianGroupWorklogAccess() {
      if (
        (this.customRules || {}).allowedWorklogAccess ===
        'technician_or_technician_group'
      ) {
        return true
      }
      return false
    },
    isAllowedWorklogAddAccess() {
      if (this.customRules.isAllowedWorklogAccessForTask) {
        return true
      } else if ((this.customRules || {}).allowedWorklogAccess !== '') {
        if (
          this.isOnlyAssigneeWorklogAccess ||
          this.isOnlyTechnicianGroupWorklogAccess ||
          this.isAllTechnicianWorklogAccess
        ) {
          return true
        }
        if (
          this.isAssigneeOrTechnicianGroupWorklogAccess &&
          ((this.resource.technicianId > 0 &&
            this.resource.technicianId === this.user.id) ||
            (this.resource.groupId > 0 &&
              (this.user.groups || []).indexOf(this.resource.groupId) >= 0))
        ) {
          return true
        }
        return false
      }
      return false
    },
  },
  created() {
    const createWorklogHandler = () => {
      this.$refs.crudContainerRef.showCreateForm()
    }
    Bus.$on('create-worklog', createWorklogHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('create-worklog', createWorklogHandler)
    })
  },
  methods: {
    refresh() {
      this.$refs.crudContainerRef.refresh()
    },
    getWorkLogs(limit, offset) {
      // @TODO Apply filters here
      const api =
        this.moduleName === this.$constants.PROJECT
          ? getProjectWorklogApi
          : getWorklogApi
      return api(this.moduleName, this.resourceId, limit, offset)
    },
    addWorkLog(data) {
      return createWorklogApi(this.moduleName, this.resourceId, {
        ...data,
      }).then((response) => {
        this.refresh()
        setTimeout(() => {
          this.$emit('refresh-worklog')
        })
      })
    },
    updateWorkLog(data) {
      return updateWorklogApi(this.moduleName, this.resourceId, data).then(
        (response) => {
          this.refresh()
          setTimeout(() => {
            this.$emit('refresh-worklog')
          })
        }
      )
    },
    removeWorkLog(data) {
      return deleteWorklogApi(this.moduleName, this.resourceId, data).then(
        (response) => {
          this.refresh()
          setTimeout(() => {
            this.$emit('refresh-worklog')
          })
        }
      )
    },
    calculateTimeTaken(item) {
      if (item.timeTaken) {
        const timeTaken = this.$options.filters.duration(
          item.timeTaken,
          undefined,
          false
        )
        return `${timeTaken}`
      }
      return 0
    },
    isUpdateable(item) {
      if (this.customRules.isAllowedWorklogAccessForTask) {
        return true
      } else {
        const technician = this.technicianOptions.find(
          (f) => f.id === item.technicianId
        )
        if ((this.customRules || {}).allowedWorklogAccess !== '') {
          if (
            (this.isOnlyAssigneeWorklogAccess &&
              item.technicianId === this.user.id) ||
            this.isAllTechnicianWorklogAccess ||
            (this.isOnlyTechnicianGroupWorklogAccess &&
              (technician.groups || []).indexOf(this.resource.groupId) >= 0)
          ) {
            return true
          }
          if (
            this.isAssigneeOrTechnicianGroupWorklogAccess &&
            ((item.technicianId === this.resource.technicianId &&
              item.technicianId === this.user.id) ||
              ((this.user.groups || []).indexOf(this.resource.groupId) >= 0 &&
                (technician.groups || []).indexOf(this.groupId) >= 0))
          ) {
            return true
          }
          return false
        } else {
          return false
        }
      }
    },
  },
}
</script>
