<template>
  <Component :is="layout">
    <Transition
      :name="transitionName"
      :mode="transitionMode"
      :enter-active-class="transitionEnterActiveClass"
    >
      <slot />
    </Transition>
  </Component>
</template>

<script>
const DEFAULT_TRANSITION = 'slide'
const DEFAULT_TRANSITION_MODE = 'out-in'

export default {
  name: 'FlotoTransitionedRouter',
  props: {
    layout: { type: [String, Object], default: 'Layout' },
  },
  data() {
    return {
      prevHeight: 0,
      transitionName: DEFAULT_TRANSITION,
      transitionMode: DEFAULT_TRANSITION_MODE,
      transitionEnterActiveClass: '',
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName =
        to.meta.transitionName || from.meta.transitionName || DEFAULT_TRANSITION

      if (transitionName === 'slide') {
        transitionName = 'slideLeft'
      }

      this.transitionMode = DEFAULT_TRANSITION_MODE
      this.transitionEnterActiveClass = `${transitionName}-enter-active`

      this.transitionName = transitionName

      next()
    })
  },
}
</script>
