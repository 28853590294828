var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col hierarchy-explorer"},[(!_vm.addAtBottom)?_c('div',{staticClass:"mb-4"},[_vm._t("add-action",null,{"add":_vm.handleAddItem})],2):_vm._e(),_vm._t("before-list"),_c('NestedSortableList',_vm._b({attrs:{"value":_vm.data,"sortable":_vm.sortable,"max-level":_vm.maxLevel,"allow-drop":_vm.allowDrop,"update-children":_vm.handleChildrenChange},on:{"change":_vm.handleItemsUpdated,"move":function ($event, parent) { return _vm.$emit('move', $event); },"add":function ($event, parent) { return _vm.$emit('add', $event, parent); },"remove":function ($event, parent) { return _vm.$emit('remove', $event, parent); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
      var canAdd = ref.canAdd;
      var updateChildren = ref.updateChildren;
      var replaceChildren = ref.replaceChildren;
      var toggle = ref.toggle;
      var parent = ref.parent;
return [_c('div',{staticClass:"hierarchy-item flex items-center min-w-0",class:{ 'cursor-move': _vm.sortable, 'mb-3': _vm.useMargin }},[_c('div',{staticClass:"title-container flex flex-1 items-center min-w-0",class:{
            'with-bg': _vm.useBg,
            seperator: _vm.useSeparator,
            'active-item': _vm.activeItem && _vm.activeItem.id === item.id,
          }},[_c('div',{staticClass:"cursor-pointer font-semibold text-color",class:{
              invisible: (item.children || []).length === 0,
              'bg-transparent': (item.children || []).length > 0 && !_vm.useBg,
              hidden: _vm.removeExpandIcon,
            },attrs:{"size":"lg"},on:{"click":toggle}},[_c('MIcon',{staticClass:"mx-1",attrs:{"name":("chevron-" + (item.expanded ? 'down' : _vm.isRtl ? 'left' : 'right'))}})],1),_vm._t("default",null,{"item":item,"canAdd":canAdd,"toggle":toggle,"parent":parent,"update":function ($event) { return _vm.updateItem($event, parent, replaceChildren); },"addChildren":function ($event) { return _vm.handleAddChildren($event, item, replaceChildren); },"remove":function () { return _vm.handleRemove(item, parent, function (children) { return replaceChildren(children, parent); }
                ); }})],2),_c('div',{staticClass:"ml-1"},[_vm._t("item-add-action",null,{"item":item,"canAdd":canAdd,"addChildren":function ($event) { return _vm.handleAddChildren($event, item, replaceChildren); }})],2)])]}}],null,true)},'NestedSortableList',_vm.$attrs,false)),_vm._t("after-list"),(_vm.addAtBottom)?_c('div',{staticClass:"mt-4"},[_vm._t("add-action",null,{"add":_vm.handleAddItem})],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }