<template>
  <div class="flex flex-col min-w-0">
    <slot v-if="showNoData && options.length <= 0" name="no-data">
      <FlotoNoData v-if="showNoData && options.length <= 0" size="small" />
    </slot>
    <MarkedContent
      v-for="(option, index) in options"
      :key="option.id"
      class="tree-single-item"
      :text="highlightTerm"
    >
      <div>
        <div class="flex items-center item-container">
          <a
            v-if="(option.children || []).length && !option.expanded"
            class="text-neutral marked-content-a -ml-3 pr-2"
            @click="options[index].expanded = true"
          >
            <slot name="expand-icon">
              <MIcon name="chevron-right" />
            </slot>
          </a>
          <a
            v-if="(option.children || []).length && option.expanded"
            class="text-neutral marked-content-a -ml-3 pr-2"
            @click="options[index].expanded = false"
          >
            <slot name="collapse-icon">
              <MIcon name="chevron-down" />
            </slot>
          </a>
          <div
            :id="option.name"
            class="item-text flex-grow rounded1"
            :class="{
              active: isSelected(option),
              disabled: isDisabled(option),
            }"
          >
            <slot
              name="item"
              :item="option"
              :select="() => handleChange(option)"
            >
              <div
                :class="{ disabled: isDisabled(option) }"
                class="cursor-pointer block py-1 pl-2 pr-1 rounded1"
                :style="{ margin: '2px 0px', height: 'auto' }"
                @click="handleChange(option)"
              >
                {{ option.name }}
              </div>
            </slot>
          </div>
        </div>
        <!-- :style="{ marginLeft: `${(level + 1) * levelMargin}px` }" -->
        <div
          v-if="(option.children || []).length && option.expanded"
          :style="
            isRtl ? { marginRight: `40px` } : { marginLeft: `${levelMargin}px` }
          "
        >
          <TreeList
            v-bind="$attrs"
            :multiple="multiple"
            :highlight-term="highlightTerm"
            :show-no-data="showNoData"
            :level="level + 1"
            :options="option.children"
            :level-margin="levelMargin"
            :only-leaf-node-selectable="onlyLeafNodeSelectable"
            :value="value"
            @change="$listeners.change"
          >
            <template v-slot:item="{ item, select }">
              <slot
                name="item"
                :item="item"
                :select="select"
                :disabled="onlyLeafNodeSelectable"
              >
              </slot>
            </template>
          </TreeList>
        </div>
      </div>
    </MarkedContent>
  </div>
</template>

<script>
import MarkedContent from '@modules/admin/components/marked-content'
import { authComputed } from '@state/modules/auth'

export default {
  name: 'TreeList',
  components: {
    MarkedContent,
  },
  props: {
    highlightTerm: { type: String, default: undefined },
    value: { type: [Number, Array, String], default: undefined },
    multiple: { type: Boolean, default: false },
    // eslint-disable-next-line
    showNoData: { type: Boolean, default: true },
    levelMargin: { type: Number, default: 20 },
    level: { type: Number, default: 0 },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    onlyLeafNodeSelectable: { type: Boolean, default: false },
  },
  computed: {
    ...authComputed,
  },
  methods: {
    handleChange(option) {
      // check leaf node only selection
      if (this.isDisabled(option) || this.isSelected(option)) {
        return
      }
      const value = this.multiple ? this.value || [] : this.value
      this.$emit(
        'change',
        Array.isArray(value) ? [...value, option.id] : option.id
      )
    },
    isSelected(option) {
      const value = this.value
      if (!value) {
        return false
      }
      return Array.isArray(value)
        ? value.indexOf(option.id) >= 0
        : value === option.id
    },
    isDisabled(option) {
      return (option.children || []).length && this.onlyLeafNodeSelectable
    },
  },
}
</script>

<style lang="less" scoped>
.tree-single-item {
  .item-text {
    &:hover,
    &.active {
      color: var(--dropdown-selected-text);
      background: var(--dropdown-hover-bg);
    }

    &.disabled {
      background: unset;
      opacity: 0.5;
    }
  }
}
</style>
