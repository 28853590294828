<template>
  <MRow class="px-4">
    <MCol>
      <MRow>
        <MCol :sm="6" :size="12">
          <FlotoFormItem
            id="technician-picker"
            rules="required|nonzero"
            :label="$tc('technician')"
          >
            <FlotoTechnicianPicker
              v-model="worklog.technicianId"
              mandatory-selection
              required
              as-input
              :group-id="allowedTechnicianGroupIds"
              :disabled="isDisabled"
            />
          </FlotoFormItem>
        </MCol>
        <MCol>
          <FlotoFormItem :validation-label="$t('type')">
            <MTooltip>
              <template v-slot:trigger>
                <MIcon
                  name="stopwatch"
                  size="2x"
                  class="mt-4 cursor-pointer"
                  :class="{
                    'text-secondary-red': !worklog.timeBasedWorkLog,
                    'text-secondary-green': worklog.timeBasedWorkLog,
                  }"
                  @click="handleToggleTimer"
                />
              </template>
              {{
                worklog.timeBasedWorkLog
                  ? `${$tc('time_is_on')}`
                  : `${$tc('time_is_off')}`
              }}
            </MTooltip>
            <!-- <MRadioGroup
              v-model="worklog.timeBasedWorkLog"
              as-button
              :disabled="Boolean(worklog.id)"
              :options="[
                { text: $t('manual'), value: false },
                {
                  text: $t('timer'),
                  value: true,
                },
              ]"
            /> -->
          </FlotoFormItem>
        </MCol>
      </MRow>
      <template v-if="!worklog.timeBasedWorkLog">
        <MRow>
          <MCol>
            <FlotoFormItem
              id="start-date-picker"
              :label="`${$t('start')} ${$t('date')}`"
              rules="required|nonzero"
            >
              <FlotoDatePicker
                v-model="worklog.timeRange.startTime"
                :max-date="worklog.timeRange.endTime"
              />
            </FlotoFormItem>
          </MCol>
          <MCol>
            <FlotoFormItem
              id="end-date-picker"
              :label="`${$t('end')} ${$t('date')}`"
              rules="required|nonzero"
            >
              <FlotoDatePicker
                v-model="worklog.timeRange.endTime"
                :min-date="worklog.timeRange.startTime"
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
        <MRow>
          <MCol>
            <FlotoFormItem id="time-taken-input" :label="$tc('time_taken')">
              {{ worklog.timeTakenDisplay }}
              {{ /*$tc('hours', worklog.timeTakenDisplay)*/ }}
            </FlotoFormItem>
          </MCol>
        </MRow>
      </template>
      <MRow>
        <MCol>
          <FlotoFormItem
            id="description-input"
            v-model="worklog.description"
            :label="$t('description')"
            rules="description"
            auto-focus
            :auto-size="{ minRows: 8 }"
            type="textarea"
            :placeholder="$t('description')"
          />
        </MCol>
      </MRow>
    </MCol>
  </MRow>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import { TechnicianComputed } from '@state/modules/technician'
// import Moment from 'moment'

export default {
  name: 'WorkLogForm',
  model: {
    event: 'change',
  },
  props: {
    worklog: { type: Object, required: true },
    groupId: { type: Number, default: 0 },
    resource: {
      type: Object,
      default() {
        return {}
      },
    },
    customRules: {
      type: Object,
      default() {
        return {}
      },
    },
    resetForm: { type: Function, required: true },
  },
  computed: {
    ...authComputed,
    ...TechnicianComputed,
    isDisabled() {
      const technician = this.getCurrentUser
      if ((this.customRules || {}).allowedWorklogAccess === 'technicianId') {
        return true
      }
      if (
        ((this.customRules || {}).allowedWorklogAccess === 'groupId' ||
          (this.customRules || {}).allowedWorklogAccess ===
            'technician_or_technician_group') &&
        this.resource.groupId > 0 &&
        (technician.groups || []).indexOf(this.resource.groupId) === -1
      ) {
        return true
      }
      return false
    },
    allowedTechnicianGroupIds() {
      const technician = this.getCurrentUser
      if (
        (this.customRules || {}).allowedWorklogAccess === 'groupId' ||
        (this.customRules || {}).allowedWorklogAccess ===
          'technician_or_technician_group'
      ) {
        return this.worklog.id &&
          this.resource.groupId &&
          (technician.groups || []).indexOf(this.resource.groupId) === -1
          ? this.worklog.technicianId
          : this.resource.groupId
      }
      return 0
    },
    getCurrentUser() {
      return this.technicianOptions.find(
        (f) => f.id === this.worklog.technicianId
      )
    },
  },
  watch: {
    'worklog.timeRange.endTime': {
      immediate: true,
      handler() {
        this.calculateTimeTaken()
      },
    },
    'worklog.timeRange.startTime': {
      immediate: true,
      handler() {
        this.calculateTimeTaken()
      },
    },
  },
  methods: {
    handleToggleTimer() {
      if (this.worklog.id) {
        return
      }
      const timeBasedWorkLog = this.worklog.timeBasedWorkLog
      const updatedData = {
        ...this.worklog,
        timeBasedWorkLog: !timeBasedWorkLog,
      }
      this.resetForm({
        ...updatedData,
        ...(updatedData.timeBasedWorkLog
          ? {
              workLogState: 'start',
              // timeRange: {
              //   startTime: updatedData.timeRange.startTime,
              //   endTime: 0,
              // },
            }
          : {}),
      })
    },
    calculateTimeTaken() {
      if (this.worklog.timeRange) {
        if (
          this.worklog.timeRange.startTime &&
          this.worklog.timeRange.endTime
        ) {
          const diff =
            this.worklog.timeRange.endTime - this.worklog.timeRange.startTime
          // this.worklog.timeTakenDisplay = Math.ceil(
          //   Moment.duration(diff, 'milliseconds').asHours()
          // )
          this.worklog.timeTakenDisplay = this.$options.filters.duration(
            diff,
            undefined,
            false
          )
          this.worklog.timeTaken = Math.ceil(diff)
        }
        return 0
      }
      return 0
    },
  },
}
</script>
