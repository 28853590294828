<template>
  <div class="hierarchy-item flex items-center min-w-0" @click="$emit('click')">
    <div
      class="title-container flex flex-1 items-center min-w-0"
      style="margin-right: 0"
      :class="{
        'mb-2': useMargin,
        'cursor-move': !disabled,
        'with-bg': useBg,
        seperator: useSeparator,
        'active-item text-primary': active,
        'text-neutral': !active,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortableListItem',
  props: {
    disabled: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    useSeparator: { type: Boolean, default: false },
    useBg: { type: Boolean, default: false },
    useMargin: { type: Boolean, default: false },
  },
}
</script>
