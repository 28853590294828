<template>
  <div>
    <MDropdown ref="dropdownMenu">
      <template v-slot:trigger>
        <MIcon name="ellipsis-v" size="lg" class="mx-1 cursor-pointer" />
      </template>
      <template v-slot:menu>
        <MMenu class="action-menu">
          <MMenuItem @click="blockNumber">
            <MIcon name="ban" class="action-menu-icon" />
            <span class="ml-1 action-menu-text">
              {{ $tc('block_number') }}
            </span>
          </MMenuItem>
        </MMenu>
      </template>
    </MDropdown>
  </div>
</template>

<script>
export default {
  name: 'ActionMenu',
  props: {
    resource: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    hide() {
      this.$refs.dropdownMenu.hide()
    },
  },
}
</script>
