<template>
  <MRow>
    <MCol>
      <FlotoFormItem id="technicians-picker" :label="$tc('technician', 2)">
        <FlotoTechnicianPicker
          v-model="formData.technicians"
          as-input
          multiple
        />
      </FlotoFormItem>
      <FlotoFormItem
        id="technician-group-picker"
        :label="$t('technician_group')"
      >
        <FlotoTechnicianGroupPicker
          v-model="formData.technicianGroups"
          as-input
          multiple
        />
      </FlotoFormItem>
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'WatcherForm',
  model: {
    event: 'change',
  },
  props: {
    open: { type: Boolean, default: false },
    value: { type: Object, default: undefined },
  },
  computed: {
    formData: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
  },
}
</script>
