<template>
  <MTooltip>
    <template v-slot:trigger>
      <FlotoDot
        class="mx-1"
        :bg="
          loggedInTechnicianDetails.length
            ? colors.success
            : colors['neutral-light']
        "
      />
      <span v-if="showInfo">{{ userName }}</span>
    </template>
    <div
      v-if="loggedInTechnicianDetails.length && showInfo"
      class="text-center"
    >
      <div>{{ $tc('logged_in') }}</div>
      <div style="max-height: 100px; overflow: auto">
        <div v-for="item in loggedInTechnicianDetails" :key="item.date">
          <div>
            <small :class="item.loginUser ? 'text-success' : ''">
              {{
                `${$tc('using')} ${item.browserName} ${$tc('from')} ${
                  item.ipAddress
                } ${$tc('at')} `
              }}
              ({{ item.date | datetime('DD/MM/YYYY hh:mm A') }})
            </small>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="showInfo">{{ $tc('logged_out') }}</div>
  </MTooltip>
</template>

<script>
import { colors } from '@utils/color'
import { authComputed } from '@state/modules/auth'

export default {
  name: 'TechnicianAvailabiltyStatus',
  props: {
    userName: { type: String, default: '' },
    userId: { type: Number, required: true },
    loggedInTechnicians: { type: Object, required: true },
    // eslint-disable-next-line
    showInfo: { type: Boolean, default: true },
  },
  data() {
    this.colors = colors
    return {}
  },

  computed: {
    ...authComputed,
    loggedInTechnicianDetails() {
      const allSessions = []
      Object.keys(this.loggedInTechnicians).map((id) => {
        if (
          this.userId === parseInt(id) &&
          (this.loggedInTechnicians[id] || []).length
        ) {
          this.loggedInTechnicians[id].forEach((user) => {
            allSessions.push(user)
          })
        }
      })
      return allSessions
    },
  },
}
</script>
