<template>
  <div class="flex flex-col hierarchy-explorer">
    <div v-if="!addAtBottom" class="mb-4">
      <slot name="add-action" :add="handleAddItem" />
    </div>
    <slot name="before-list" />
    <NestedSortableList
      :value="data"
      :sortable="sortable"
      :max-level="maxLevel"
      :allow-drop="allowDrop"
      :update-children="handleChildrenChange"
      v-bind="$attrs"
      @change="handleItemsUpdated"
      @move="($event, parent) => $emit('move', $event)"
      @add="($event, parent) => $emit('add', $event, parent)"
      @remove="($event, parent) => $emit('remove', $event, parent)"
    >
      <template
        v-slot:item="{
          item,
          canAdd,
          updateChildren,
          replaceChildren,
          toggle,
          parent,
        }"
      >
        <div
          class="hierarchy-item flex items-center min-w-0"
          :class="{ 'cursor-move': sortable, 'mb-3': useMargin }"
        >
          <div
            class="title-container flex flex-1 items-center min-w-0"
            :class="{
              'with-bg': useBg,
              seperator: useSeparator,
              'active-item': activeItem && activeItem.id === item.id,
            }"
          >
            <div
              class="cursor-pointer font-semibold text-color"
              :class="{
                invisible: (item.children || []).length === 0,
                'bg-transparent': (item.children || []).length > 0 && !useBg,
                hidden: removeExpandIcon,
              }"
              size="lg"
              @click="toggle"
            >
              <MIcon
                class="mx-1"
                :name="`chevron-${
                  item.expanded ? 'down' : isRtl ? 'left' : 'right'
                }`"
              />
            </div>
            <slot
              :item="item"
              :can-add="canAdd"
              :toggle="toggle"
              :parent="parent"
              :update="($event) => updateItem($event, parent, replaceChildren)"
              :add-children="
                ($event) => handleAddChildren($event, item, replaceChildren)
              "
              :remove="
                () =>
                  handleRemove(item, parent, (children) =>
                    replaceChildren(children, parent)
                  )
              "
            />
          </div>
          <div class="ml-1">
            <slot
              name="item-add-action"
              :item="item"
              :can-add="canAdd"
              :add-children="
                ($event) => handleAddChildren($event, item, replaceChildren)
              "
            />
          </div>
        </div>
      </template>
    </NestedSortableList>
    <slot name="after-list" />
    <div v-if="addAtBottom" class="mt-4">
      <slot name="add-action" :add="handleAddItem" />
    </div>
  </div>
</template>

<script>
import SortBy from 'lodash/sortBy'
import Omit from 'lodash/omit'
import FindIndex from 'lodash/findIndex'
import NestedSortableList from '@components/sortable/nested-sortable-list'
import { authComputed } from '@state/modules/auth'

export default {
  name: 'HierarchyExplorer',
  components: { NestedSortableList },
  model: {
    event: 'change',
  },
  props: {
    // eslint-disable-next-line
    sortable: { type: Boolean, default: true },
    // eslint-disable-next-line
    useBg: { type: Boolean, default: true },
    // eslint-disable-next-line
    useMargin: { type: Boolean, default: true },
    activeItem: { type: Object, default: undefined },
    containerClasses: { type: String, default: undefined },
    useSeparator: { type: Boolean, default: false },
    addAtBottom: { type: Boolean, default: false },
    defaultValue: { type: Array, required: true },
    addFn: { type: Function, default: undefined },
    removeFn: { type: Function, default: undefined },
    bulkUpdateFn: { type: Function, default: undefined },
    updateFn: { type: Function, default: undefined },
    maxLevel: { type: Number, default: 3 },
    removeExpandIcon: { type: Boolean, default: false },
    // eslint-disable-next-line
    syncData: { type: Boolean, default: true },
    notifyParent: { type: Boolean, default: false },
    value: {
      type: Array,
      default() {
        return []
      },
    },
    allowDrop: { type: Boolean, default: false },
  },
  data() {
    return {
      formData: this.defaultValue,
    }
  },
  computed: {
    ...authComputed,
    data: {
      get() {
        if (this.notifyParent) {
          return this.value
        }
        return this.formData
      },
      set(value) {
        if (this.notifyParent) {
          this.$emit('change', value)
        } else {
          this.formData = value
        }
      },
    },
  },
  watch: {
    defaultValue(newValue) {
      if (!this.notifyParent) {
        this.data = newValue
      }
    },
  },
  methods: {
    handleItemsUpdated(event) {
      const { items } = event
      if (this.$listeners && this.$listeners.move) {
        return
      }
      if (!this.bulkUpdateFn) {
        throw new Error('Bulk update function is not provided')
      }
      this.bulkUpdateFn(items).then((response) => {
        if (this.notifyParent) {
          return (this.data = response)
        }
        this.data = items
      })
    },
    handleAddItem(data) {
      if (!this.addFn) {
        throw new Error('Add function is not provided')
      }
      return this.addFn(data).then((data) => {
        this.data = SortBy([...this.data, ...data], 'order')
      })
    },
    handleAddChildren(children, item, updateChildrenFn) {
      if (!this.addFn) {
        throw new Error('Add function is not provided')
      }
      return this.addFn(children, item).then((data) => {
        const allChildren = SortBy([...data, ...(item.children || [])], 'order')
        updateChildrenFn(allChildren)
      })
    },
    handleRemove(item, parent, updateChildrenFn) {
      if (!this.removeFn) {
        throw new Error('Remove function is not provided')
      }
      return this.removeFn(item).then((data) => {
        if (parent) {
          updateChildrenFn(parent.children.filter((c) => c.id !== item.id))
        } else {
          // root level item removed
          this.data = this.data.filter((c) => c.id !== item.id)
        }
      })
    },
    handleChildrenChange(children, parent) {
      if (!this.bulkUpdateFn) {
        throw new Error('Bulk update function is not provider')
      }
      return this.bulkUpdateFn(children, parent).then((response) => {
        if (this.notifyParent) {
          this.data = response
        }
      })
    },
    updateItem(item, parent, updateChildrenFn) {
      if (!this.updateFn) {
        throw new Error('Update function is not provider')
      }
      // pass `this.data` for latest data when sync-data is false
      return this.updateFn(item, this.data).then((data) => {
        if (this.syncData) {
          if (parent) {
            const childrenIndex = FindIndex(parent.children, { id: item.id })
            const updatedChildren = [
              ...parent.children.slice(0, childrenIndex),
              {
                ...parent.children[childrenIndex],
                ...Omit(data, ['children']),
              },
              ...parent.children.slice(childrenIndex + 1),
            ]
            updateChildrenFn(updatedChildren, parent)
          } else {
            const index = FindIndex(this.data, { id: item.id })
            this.data = [
              ...this.data.slice(0, index),
              { ...this.data[index], ...Omit(data, ['children']) },
              ...this.data.slice(index + 1),
            ]
          }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.text-color {
  color: var(--page-text-color);
}
</style>
