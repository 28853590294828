<template>
  <span v-if="textOnly">{{ value.join(',') }}</span>
  <div
    v-else-if="(value || []).filter(Boolean).length"
    class="flex-1 min-w-0 flex"
    :class="{ 'flex-wrap': wrap, 'flex-col': vertical }"
  >
    <MTag
      v-for="(item, index) in value.slice(0, maxItems)"
      :key="`${index}-${item}`"
      :closable="closable"
      :variant="variant"
      class="mr-1 inline-flex min-w-0 mb-1 mt-1"
      @close="$emit('close', getDropdownItemValue(item))"
    >
      <span
        class="text-ellipsis md-text-sm mx-1"
        :title="
          typeof item === 'object' ? item.text || item.name || item.label : item
        "
      >
        {{
          typeof item === 'object' ? item.text || item.name || item.label : item
        }}
      </span>
    </MTag>
    <template v-if="value.slice(maxItems).length">
      <component
        :is="usePopover ? 'MPopover' : 'MPopper'"
        v-bind="
          usePopover
            ? {
                placement: 'bottom',
                transitionName: 'none',
                overlayClassName: 'readable-content-overlay1 picker-overlay',
              }
            : {
                overlayClassName: 'readable-content-overlay1 picker-overlay',
                placement: 'bottom',
              }
        "
        style="line-height: 22px"
        :disabled="disabled"
      >
        <MTag
          slot="trigger"
          :closable="false"
          :variant="variant"
          class="mr-1 inline-flex min-w-0 mb-1 mt-1 cursor-pointer"
        >
          +{{ value.slice(maxItems).length }}
        </MTag>
        <div
          class="ant-menu ant-menu-vertical ant-menu-root ant-menu-light ant-dropdown-menu"
        >
          <div
            v-for="(item, index) in value.slice(maxItems)"
            :key="`${item}-${index}`"
            class="ant-menu-item scroll-dropdown-menu-item ant-dropdown-menu-item value-active-item"
            >{{
              typeof item === 'object'
                ? item.text || item.name || item.label
                : item
            }}</div
          >
        </div>
      </component>
    </template>
  </div>
</template>

<script>
import { getDropdownItemValue } from '@utils/value'
export default {
  name: 'SelectedItemPills',
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: undefined,
    },
    maxItems: { type: Number, default: 1 },
    value: { type: [Array], required: true },
    closable: { type: Boolean, default: false },
    // eslint-disable-next-line
    wrap: { type: Boolean, default: true },
    usePopover: { type: Boolean, default: false },
    textOnly: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    this.getDropdownItemValue = getDropdownItemValue
    return {}
  },
}
</script>
