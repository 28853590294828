<template>
  <div
    class="flex flex-1 flex-col min-w-0 dropdown-multiple-trigger"
    :class="{ 'cursor-pointer': !disabled }"
    @click.prevent.stop="handleClick"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseExit"
  >
    <template v-if="disabled">
      <div
        v-if="(selectedItems || []).length"
        :class="[
          {
            'flex items-center min-w-0 px-1 rounded border border-solid border-neutral-lighter trigger':
              !asTag,
          },
          inputClasses,
        ]"
      >
        <SelectedItemPills
          :value="(selectedItems || []).map((i) => map[i] || i || map[0])"
          :closable="canRemoveSelectedItemsPill"
          :variant="
            !asTag
              ? 'dropdown-trigger'
              : canRemoveSelectedItemsPill
              ? 'default'
              : 'neutral-lighter'
          "
          :use-popover="$attrs['use-popover']"
          :max-items="
            displayAllSelectedItemsPill
              ? (selectedItems || []).length
              : undefined
          "
          @close="(id) => handleRemoveTag(id)"
        />
      </div>
      <div
        v-else
        :class="[
          {
            'flex items-center min-w-0 px-1 rounded border border-solid border-neutral-lighter trigger':
              !asTag,
          },
          inputClasses,
        ]"
        :style="!asTag ? { padding: '5px 8px' } : {}"
      >
        <span class="text-neutral-light flex-1 label-list pl-2">
          {{ placeholder }}
        </span>
        <MIcon
          type="fal"
          name="chevron-down"
          size="sm"
          class="cursor-pointer text-neutral-light mx-1"
          :class="{ 'cursor-not-allowed': disabled }"
        />
      </div>
    </template>
    <div
      v-else
      :class="[{ 'bg-neutral-lightest': disabled }, inputClasses]"
      class="flex items-center min-w-0 px-1 rounded border border-solid border-neutral-lighter trigger"
    >
      <SelectedItemPills
        v-if="(selectedItems || []).length"
        :value="(selectedItems || []).map((i) => map[i] || i || map[0])"
        :use-popover="$attrs['use-popover']"
        variant="dropdown-trigger"
        :closable="!disabled"
        :disabled="!disabled"
        @close="(id) => handleRemoveTag(id)"
      />
      <!-- <div
          v-if="(selectedItems || []).length"
          class="flex-1 min-w-0 flex flex-wrap pt-1"
        >
          <MTag
            v-for="id in selectedItems"
            :key="id"
            :title="getTextValue(id)"
            :style="triggerTagStyle"
            :closable="(!disabled && !(map[id] || {}).disabled) || archived"
            variant="dropdown-trigger"
            class="mr-1 inline-flex min-w-0 mb-1"
            :class="{ 'cursor-auto': disabled }"
            @close="handleRemoveTag(id)"
          >
            <span class="text-ellipsis md-text-sm mx-1">{{
              getTextValue(id)
            }}</span>
          </MTag>
        </div> -->
      <div
        v-else
        class="text-neutral-light flex-1 label-list pl-2"
        style="height: 29px; line-height: 27px"
      >
        {{ placeholder }}
      </div>
      <MIcon
        v-if="isActiveHover && allowClear && !disabled"
        type="fas"
        name="times-circle"
        size="sm"
        class="cursor-pointer text-neutral-light mx-1"
        @click.stop="$emit('change', [])"
      />
      <MIcon
        v-if="!isActiveHover && !disabled"
        type="fal"
        name="chevron-down"
        size="sm"
        class="cursor-pointer text-neutral-light mx-1"
      />
    </div>
    <!-- <MDivider v-if="!disabled" class="my-1" /> -->
  </div>
</template>

<script>
import SelectedItemPills from './selected-item-pills.vue'
export default {
  name: 'MultipleTrigger',
  components: { SelectedItemPills },
  props: {
    selectedItems: { type: [Array, Object], default: undefined },
    allowClear: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default() {
        return this.$tc('select')
      },
    },
    textOnly: { type: Boolean, default: false },
    asTag: { type: Boolean, default: false },
    displayAllSelectedItemsPill: { type: Boolean, default: false },
    canRemoveSelectedItemsPill: { type: Boolean, default: false },
    options: { type: Array, required: true },
    inputClasses: { type: [String, Object, Array], default: undefined },
    triggerTagStyle: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      map: {},
      isActiveHover: false,
    }
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        const m = {}
        this.options.forEach((o) => (m[o.value || o.id || o.key || 0] = o))
        this.map = Object.freeze(m)
      },
    },
  },
  methods: {
    handleClick() {
      if (this.disabled) {
        return
      }
      this.$emit('click')
    },
    handleMouseExit() {
      if (this.disabled) {
        return
      }
      this.isActiveHover = false
    },
    handleMouseEnter() {
      if ((this.selectedItems || []).length) {
        this.isActiveHover = true
      }
    },
    handleRemoveTag(id) {
      this.$emit(
        'change',
        this.selectedItems.filter((i) => i !== id)
      )
    },
    getTextValue(id) {
      if (id) {
        return (this.map[id] || {}).text || (this.map[id] || {}).name || id
      }
      const item =
        this.options.find(
          (o) => id === o.id || id === o.value || id === o.key
        ) || {}
      return item.label || item.name || item.text || id
    },
  },
}
</script>

<style lang="less" scoped>
.dropdown-multiple-trigger {
  .material-input {
    padding-right: 0;
    padding-left: 0;
    border: none;
  }

  & .trigger {
    background-color: var(--page-background-color);
  }
}

.disabled-trigger {
  padding: 5px 11px;
}
</style>
