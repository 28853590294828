var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SortableList',_vm._b({class:{
    'sortable-list': _vm.level === 1,
    'sortable-list-child': _vm.level !== 1,
  },attrs:{"value":_vm.value,"child-key":_vm.childKey,"disabled":!_vm.sortable,"group-name":("sortable-" + _vm.level),"allow-drop":_vm.allowDrop,"ghost-class":"test","chosen-class":"test","filter":".not-draggable","level":_vm.level},on:{"update":_vm.handleItemsUpdated,"add":_vm.handleAddItem,"remove":_vm.handleRemoveItem},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
  var updateItem = ref.updateItem;
return [_vm._t("item",function(){return [_vm._v(" "+_vm._s(item.name)+" ")]},{"item":item,"updateChildren":function (children) { return _vm.handleChildrenChange(children, item); },"replaceChildren":function (children) { return _vm.handleChildrenReplace(children, item); },"updateItem":updateItem,"toggle":function () { return _vm.handleItemExpand(item); },"canAdd":_vm.level < _vm.maxLevel,"level":_vm.level,"parent":_vm.parent}),_c('NestedSortableList',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.expanded && _vm.level < _vm.maxLevel),expression:"item.expanded && level < maxLevel"}],style:({ paddingLeft: (_vm.levelMargin + "px") }),attrs:{"value":item.children || [],"max-level":_vm.maxLevel,"sortable":_vm.sortable,"level":_vm.level + 1,"level-margin":_vm.levelMargin,"parent":item,"update-children":_vm.updateChildren,"allow-drop":_vm.allowDrop},on:{"change":function($event){return _vm.handleChildrenChange($event.items, item)}},scopedSlots:_vm._u([{key:"item",fn:function(childSlotData){return [_vm._t("item",function(){return [_vm._v(" "+_vm._s(childSlotData.item.name)+" ")]},{"toggle":function () { return _vm.handleItemExpand(childSlotData.item); },"updateChildren":function (children) { return _vm.handleChildrenChange(children, childSlotData.item); },"replaceChildren":function (children, passedParent) {
              _vm.handleChildrenReplace(
                children,
                passedParent || childSlotData.item
              )
            }},childSlotData)]}}],null,true)},'NestedSortableList',_vm.$attrs,false),_vm.listeners))]}}],null,true)},'SortableList',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }