<template>
  <div class="flex flex-col flex-1">
    <AssetSelectionList
      ref="list"
      :module-name="moduleName"
      :fetch-fn="fetchAssetFn"
      :searchable="false"
      scrollable
      show-no-data
      :has-actions="false"
    />
  </div>
</template>

<script>
import AssetSelectionList from '@components/item-selection-list/asset-list'
import { myAssetsUserByIdApi } from '@modules/asset/asset-api'
export default {
  name: 'UserAssets',
  components: { AssetSelectionList },
  props: {
    user: { type: Object, required: true },
    moduleName: {
      type: String,
      default() {
        return this.$constants.ASSET
      },
    },
  },

  methods: {
    fetchAssetFn() {
      return myAssetsUserByIdApi(this.moduleName, this.user.id)
    },
  },
}
</script>
