<template>
  <MPopover
    ref="popoverMenu"
    :destroy-tooltip-on-hide="false"
    trigger="click"
    placement="bottomRight"
    :overlay-style="{ width: '350px', zIndex: 9990 }"
    overlay-class-name="form-overlay"
  >
    <a slot="trigger" href="javascript:;">
      <MTooltip v-if="suggestedValues && suggestedFieldsMap.length">
        <template v-slot:trigger>
          <MButton
            variant="success"
            class="mx-1"
            :shadow="false"
            shape="circle"
          >
            <MIcon name="lightbulb-exclamation" />
          </MButton>
        </template>
        {{ $tc('suggested_fields') }}
      </MTooltip>
    </a>
    <h6> {{ $tc('suggested_fields') }} </h6>
    <div v-if="showSelectAllCheckbox" class="mb-1">
      <MCheckbox
        :checked="selectedFields.length === suggestedFieldsMap.length"
        @change="handleSelectAll"
      >
        {{ $tc('select') }} {{ $tc('all') }}
      </MCheckbox>
    </div>
    <MCheckboxGroup :value="selectedFields" class="w-full">
      <TransitionGroup name="placeholder">
        <MRow
          v-for="field in suggestedFieldsMap"
          :id="field.id"
          :key="field.paramName"
        >
          <MCol>
            <MCheckbox
              v-if="!field.disabled"
              :value="field"
              class="my-1"
              @change="handleChange($event, field)"
            >
              {{ field.title }}:
              <span class="font-semibold">{{ field.key }}</span>
            </MCheckbox>
          </MCol>
        </MRow>
      </TransitionGroup>
    </MCheckboxGroup>
    <MDivider class="my-1" />
    <MRow class="justify-end" :gutter="0">
      <MButton
        id="apply-btn"
        size="small"
        variant="primary"
        :loading="processing"
        :disabled="!selectedFields.length"
        @click="handleUpdate"
      >
        {{ $tc('apply') }}
      </MButton>
      <MButton
        id="apply-btn"
        size="small"
        class="mx-2"
        variant="default"
        @click="handleCancel"
      >
        {{ $tc('cancel') }}
      </MButton>
    </MRow>
  </MPopover>
</template>

<script>
import { CategoryComputed } from '@state/modules/category'
import { UserGroupsComputed } from '@state/modules/user-group'
import { updateApi } from '@modules/ticket/ticket-api'

export default {
  name: 'SuggestedFieldsBtn',
  inject: ['suggestedFieldValuesContext'],
  props: {
    resource: { type: Object, required: true },
    moduleName: { type: String, required: true },
  },
  data() {
    return {
      selectedFields: [],
      processing: false,
    }
  },
  computed: {
    ...CategoryComputed,
    ...UserGroupsComputed,
    suggestedFieldsMap() {
      // eslint-disable-next-line
      const suggestedCategory = this.requestCategories.find(
        (category) =>
          category.id === (this.suggestedValues || {}).suggestedCategoryId
      )
      // eslint-disable-next-line
      const suggestedTechnicianGroup = this.technicianGroupsOptions.find(
        (group) => group.key === (this.suggestedValues || {}).suggestedGroupId
      )
      return [
        {
          title: `${this.$tc('category')}`,
          paramName: 'categoryId',
          key: (suggestedCategory || {}).name,
          id: (suggestedCategory || {}).id,
          disabled: Boolean(
            !(suggestedCategory || {}).id ||
              !(this.suggestedValues || {}).suggestedCategoryId ||
              this.resource.categoryId
          ),
        },
        {
          title: `${this.$tc('technician_group')}`,
          paramName: 'groupId',
          key: (suggestedTechnicianGroup || {}).text,
          id: (suggestedTechnicianGroup || {}).key,
          disabled: Boolean(
            !(suggestedTechnicianGroup || {}).key ||
              !(this.suggestedValues || {}).suggestedGroupId ||
              this.resource.groupId
          ),
        },
      ].filter((i) => !i.disabled)
    },
    suggestedValues() {
      let value
      const fieldValue =
        (
          (this.suggestedFieldValuesContext || {}).suggestedFieldValues || []
        ).find((i) => i.refId === this.resource.id) || {}
      if (
        (fieldValue || {}).suggestedCategoryId ||
        (fieldValue || {}).suggestedGroupId
      ) {
        value = fieldValue
      }
      return value
    },
    showSelectAllCheckbox() {
      return this.suggestedFieldsMap.length > 1
    },
  },
  methods: {
    handleChange(isChecked, field) {
      if (isChecked) {
        this.selectedFields = [...(this.selectedFields || []), field]
      } else {
        this.selectedFields = this.selectedFields.filter((c) => c !== field)
      }
    },
    handleUpdate() {
      this.processing = true
      const fields = this.selectedFields.reduce(
        (obj, field) => Object.assign(obj, { [field.paramName]: field.id }),
        {}
      )
      return updateApi(this.moduleName, {
        id: this.resource.id,
        ...fields,
      })
        .then(() => {
          this.$emit('refresh')
          this.handleCancel()
        })
        .finally(() => {
          this.processing = false
        })
    },
    handleCancel() {
      this.selectedFields = []
      this.$refs.popoverMenu.hide()
    },

    handleSelectAll(event) {
      if (event === true) {
        this.selectedFields = this.suggestedFieldsMap
      } else {
        this.selectedFields = []
      }
    },
  },
}
</script>
