<template>
  <MRow :gutter="0">
    <MCol>
      <MInput
        id="search-box"
        v-model="searchTerm"
        class="search-box mx-1"
        :placeholder="$t('search')"
        @enterKey="handleChange({ keyword: searchTerm })"
      >
        <template v-slot:prefix>
          <MIcon name="search" />
        </template>
      </MInput>
      <MButton
        variant="transparent"
        :shadow="false"
        @click="
          handleChange({
            sortBy:
              value.sortBy === 'createdTime' ? '-createdTime' : 'createdTime',
          })
        "
      >
        <MIcon
          :name="
            `sort-amount-${value.sortBy === 'createdTime' ? 'down' : 'up'}`
          "
          size="lg"
        />
      </MButton>
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'TaskSearchBox',
  model: {
    event: 'change',
  },
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      searchTerm: '',
    }
  },
  methods: {
    handleChange(change) {
      this.$emit('change', {
        ...this.value,
        ...change,
      })
    },
  },
}
</script>
