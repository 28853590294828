<template>
  <Draggable
    :value="value"
    :group="{ name: groupName, put: putFn }"
    :animation="150"
    :fallback-on-body="true"
    :swap-threshold="0.65"
    :ghost-class="ghostClass"
    :disabled="disabled"
    :chosen-class="chosenClass"
    :tag="tag"
    v-bind="$attrs"
    @change="handleChange"
    @start="handleDragStart"
    @end="handleDragEnd"
  >
    <slot>
      <li
        v-for="item in value"
        :id="`column-selection-${item.name || item.text || item.label}`"
        :key="item.id || item.guid || item.key"
        class="sortable-item"
      >
        <slot :item="item" name="item" :update-item="handleUpdateItem" />
      </li>
    </slot>
  </Draggable>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import FindIndex from 'lodash/findIndex'
import Draggable from 'vuedraggable'
import { move } from '@utils/arr'

export default {
  name: 'SortableList',
  components: { Draggable },
  model: { event: 'change' },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Array, required: true },
    childKey: { type: String, default: 'children' },
    groupName: { type: String, default: 'sortable' },
    ghostClass: { type: String, default: undefined },
    chosenClass: { type: String, default: undefined },
    tag: { type: String, default: 'ul' },
    // eslint-disable-next-line
    allowDrop: { type: Boolean, default: true },
  },
  methods: {
    putFn() {
      if (this.allowDrop) {
        return true
      }
      return false
    },
    handleChange(data) {
      if (data.moved) {
        const newIndex = data.moved.newIndex
        const oldIndex = data.moved.oldIndex
        const newData = move(CloneDeep(this.value), oldIndex, newIndex)
        const updatedData = newData.slice(
          Math.min(oldIndex, newIndex),
          Math.max(newIndex, oldIndex) + 1
        )
        this.$emit('change', newData)
        this.$emit('update', {
          items: newData,
          updatedItems: updatedData,
          oldData: this.value,
          index: newIndex,
          item: data.moved.element,
        })
      }
      if (data.added) {
        this.$emit('add', {
          item: data.added.element,
          index: data.added.newIndex,
        })
      }
      if (data.removed) {
        this.$nextTick(() => {
          this.$emit('remove', {
            item: data.removed.element,
            index: data.removed.oldIndex,
          })
        })
      }
    },
    handleDragStart($event) {
      this.$emit('drag-start', $event)
    },
    handleDragEnd($event) {
      this.$emit('drag-end', $event)
    },
    handleUpdateItem(item) {
      const index = FindIndex(this.value, { guid: item.guid })
      const updatedItems = [
        ...this.value.slice(0, index),
        item,
        ...this.value.slice(index + 1),
      ]
      this.$emit('change', updatedItems)
      this.$emit('update', { items: updatedItems, updatedItems: [item] })
    },
  },
}
</script>

<style lang="less">
ul.sortable-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>
