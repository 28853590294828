<template>
  <ResizableTable
    :disabled="!shouldShowHeader"
    sticky-headers
    :use-scroll="false"
    :min-height="0"
    :class="{ 'flex-1': !items.length && showNoData }"
    @resize-done="columnWidths = $event"
  >
    <table class="w-full item-list-table">
      <thead v-if="shouldShowHeader">
        <tr>
          <td v-if="selectable" class="checkbox sticky">
            <MCheckbox
              v-if="items.length > 0"
              :checked="selectedItemIds.length === items.length"
              @change="toggleSelectAll"
            />
          </td>
          <td
            v-for="column in columns"
            :key="column.key"
            class="sticky"
            :class="column.class"
            :style="columnStyle(column)"
            :data-initial-width="column.width"
            :align="column.align"
          >
            <div class="flex items-center">
              <span
                :id="`list-table-${column.name}`"
                class="min-w-0 flex-1 text-ellipsis"
              >
                {{ column.name }}
              </span>
              <MIcon
                v-if="column.sortable"
                class="cursor-pointer"
                :name="
                  sortedColumn
                    ? sortedColumn.replace('-', '') === column.key
                      ? /^-/.test(sortedColumn)
                        ? 'sort-up'
                        : 'sort-down'
                      : 'sort'
                    : 'sort'
                "
                @click="applySort(column.key)"
              />
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <slot
          v-for="(item, index) in items"
          name="row"
          :item="item"
          :column-widths="columnWidths"
          :on-row-click="onRowClick"
          :index="index"
          :update="($events) => update({ ...$events, type: item.type }, item)"
          v-bind="rowSlotData"
        />
      </tbody>
    </table>
    <FlotoNoData v-if="!items.length && showNoData" :size="messageSize" />
  </ResizableTable>
</template>

<script>
import ResizableTable from '@components/table/resizable-table'
import Pick from 'lodash/pick'

export default {
  name: 'ListTable',
  components: { ResizableTable },
  inheritAttrs: false,
  props: {
    currentCursorPosition: { type: Number, default: undefined },
    selectable: { type: Boolean, required: true },
    items: { type: Array, default: () => [] },
    resourceName: { type: String, required: true },
    activeItem: { type: Object, default: null },
    view: { type: String, default: 'list' },
    columns: { type: Array, required: true },
    toggleSelectItem: { type: Function, required: true },
    selectedItemIds: { type: Array, required: true },
    toggleSelectAll: { type: Function, required: true },
    activateItem: { type: Function, default: undefined },
    edit: { type: Function, default: undefined },
    remove: { type: Function, default: undefined },
    update: { type: Function, default: undefined },
    sortedColumn: { type: String, default: undefined },
    applySort: { type: Function, default: undefined },
    // eslint-disable-next-line
    showNoData: { type: Boolean, default: true },
    messageSize: { type: String, default: undefined },
  },
  data() {
    return {
      columnWidths: [],
    }
  },
  computed: {
    rowSlotData() {
      return {
        ...this.$props,
        ...this.$attrs,
      }
    },
    shouldShowHeader() {
      if (this.selectedItemIds.length > 0) {
        return false
      }
      if (this.view === 'list') {
        return true
      }

      return false
    },
  },
  watch: {
    currentCursorPosition(newValue, oldValue) {
      if (newValue !== oldValue) {
        let row = this.$el.querySelector(`tr[data-index="${newValue}"]`)
        if (row) {
          requestAnimationFrame(() => {
            row.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
            row = null
          })
        }
      }
    },
  },
  methods: {
    columnStyle(column) {
      return Pick(column, ['width', 'minWidth'])
    },
    onRowClick(item) {
      if (this.view === 'split') {
        this.$emit('item-click', item)
      }
    },
  },
}
</script>
