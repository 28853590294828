import api from '@api'
import {
  transformTaskForServer,
  transformTask,
  tranformMovedTaskForServer,
} from '@data/task'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@/src/data/qualification'
const __t = getRootTranslator()
const __tc = getRootPluaralTranslator()

const buildQualifications = (filters) => {
  const quals = []
  if (filters.filter) {
    quals.push(
      buildRelationalQualificationStructure(
        `${filters.moduleName}.task.subject`,
        'contains',
        filters.filter
      )
    )
  }
  if (filters.stage !== undefined) {
    quals.push(
      buildRelationalQualificationStructure(
        `taskDependencyStage`,
        'equal',
        filters.stage || 0,
        'integer',
        'db'
      )
    )
  }
  if (quals.length) {
    return {
      qualDetails: buildFlatQualificationStructure(quals),
    }
  }
  return {}
}

export function getTasksApi({ moduleName, ...rest }, id, pageSize, offset) {
  return api
    .post(
      `${moduleName}/${id}/task/search/byqual`,
      buildQualifications({ moduleName, ...rest }),
      {
        params: {
          offset: offset || 0,
          size: pageSize || 1000,
          ...(rest.sortBy ? { sort_by: rest.sortBy } : {}),
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformTask),
        total: data.totalCount,
      }
    })
}

export function createTaskApi(moduleName, refId, task) {
  let url = ''
  if (moduleName && refId) {
    url = `${moduleName}/${refId}`
  }
  return api
    .post(`${url}/task`, transformTaskForServer(task), {
      message: __t('created_successfully', { resource: __tc('task') }),
    })
    .then((data) => transformTask(data))
}

export function updateTaskApi(moduleName, refId, { id, ...task }) {
  let url = ''
  if (moduleName && refId) {
    url = `${moduleName}/${refId}`
  }
  return api
    .patch(`${url}/task/${id}`, transformTaskForServer(task), {
      message: __t('updated_successfully', { resource: __tc('task') }),
    })
    .then((data) => transformTask(data))
}

export function getTaskApi(moduleName, refId, id) {
  let url = ''
  if (moduleName && refId) {
    url = `${moduleName}/${refId}`
  }
  return api.get(`${url}/task/${id}`).then((data) => transformTask(data))
}

export function deleteTaskApi(moduleName, refId, { id }) {
  let url = ''
  if (moduleName && refId) {
    url = `${moduleName}/${refId}`
  }
  return api.delete(`${url}/task/${id}`, {
    message: __t('deleted_successfully', { resource: __tc('task') }),
  })
}

export function updateTasksApi(moduleName, refId, data) {
  return api.patch(`${moduleName}/${refId}/task/bulk/update`, data, {
    message: __t('updated_successfully', {
      resource: __tc('task', Object.keys(data).length),
    }),
  })
}

export function getTaskStagesApi(moduleName, refId) {
  return api.get(`/${moduleName}/${refId}/task/stage`).then((data) => ({
    total: data.totalStage,
    activeStage: data.activeStage,
    stages: (data.stages || []).map((s) => ({
      total: s.totalTasks,
      completed: s.completedTasks,
    })),
  }))
}

export function moveTaskApi(data) {
  return api
    .post(`/moveTask`, tranformMovedTaskForServer(data), {
      message: __t('updated_successfully', {
        resource: __tc('task', Object.keys(data).length),
      }),
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformTask),
        total: data.totalCount,
      }
    })
}

export function getWorklogTotalTimeApi(taskId) {
  return api.get(`/task/${taskId}/worklog/totalTime`)
}
