<template>
  <FlotoCrudContainer
    as-table
    prepend-new-item
    :fetch-fn="getRecentCall"
    :columns="tableColumns"
  >
    <template v-slot:callTime="{ item }">
      <td class="text-ellipsis">
        {{ item.callTime | datetime }}
      </td>
    </template>
  </FlotoCrudContainer>
</template>
<script>
import { getRecentCallApi } from '@modules/contactops/contactops-api'
export default {
  name: 'RecentCalls',
  props: {
    userId: { type: Number, required: true },
  },
  data() {
    return {
      tableColumns: [
        { name: `${this.$tc('number')}`, key: 'requesterNumber' },
        { name: `${this.$tc('call_time')}`, key: 'callTime' },
        {
          name: `${this.$tc('call')} ${this.$tc('type')}`,
          key: 'callType',
        },
        {
          name: `${this.$tc('call')} ${this.$tc('status')}`,
          key: 'status',
        },
        { name: `${this.$tc('assignee')}`, key: 'assignee' },
        {
          name: `${this.$tc('call')} ${this.$tc('duration')}`,
          key: 'duration',
        },
      ],
    }
  },
  methods: {
    getRecentCall() {
      return getRecentCallApi(this.userId)
    },
  },
}
</script>
