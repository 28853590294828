<template>
  <div
    ref="container"
    class="w-full flex flex-col"
    :class="{ 'flex-1': minHeight > 0 }"
    :style="{ minHeight: `${minHeight}px` }"
  >
    <div v-if="useScroll" class="flex flex-col min-w-0 flex-1">
      <MRow class="min-h-full flex-no-wrap overflow-x-hidden" :gutter="0">
        <MCol class="flex flex-col overflow-hidden">
          <FlotoScrollView
            :show-duration="0"
            class="resizable-table-scroll-container"
          >
            <div class="columns-resizable" :class="{ sticky: stickyHeaders }">
              <div ref="resizeContainer" />
            </div>
            <slot />
          </FlotoScrollView>
        </MCol>
      </MRow>
    </div>
    <template v-else>
      <div class="columns-resizable" :class="{ sticky: stickyHeaders }">
        <div ref="resizeContainer" />
      </div>
      <slot />
    </template>
  </div>
</template>

<script>
import Throttle from 'lodash/debounce'
export default {
  name: 'ResizableTable',
  props: {
    // eslint-disable-next-line
    useScroll: { type: Boolean, default: true },
    minWidth: { type: [String, Number], default: undefined },
    stickyHeaders: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    minHeight: { type: Number, default: 150 },
  },
  data() {
    this.table = null
    this.bars = null
    this.resizeContainer = null
    this.th = null
    this.thead = null
    this.mouseDown = this.mouseDown.bind(this)
    this.mouseUp = this.mouseUp.bind(this)
    this.mouseMove = this.mouseMove.bind(this)
    this.columnWidths = []
    return {
      moving: false,
      movingIndex: 0,
    }
  },
  watch: {
    disabled(newValue) {
      if (newValue) {
        this.destroy()
      } else {
        this.$nextTick(() => {
          this.buildResizeContainer()
          this.bindEvents()
          this.$nextTick(() => {
            this.adjustBars()
          })
        })
      }
    },
  },
  created() {
    this.emitWidthChange = Throttle(this.emitWidthChange, 100)
  },
  mounted() {
    if (!this.disabled) {
      this.buildResizeContainer()
      this.bindEvents()
      requestAnimationFrame(() => {
        this.adjustBars()
      })
    }
  },
  // updated() {
  //   if (this.th) {
  //     if (this.th.length !== this.getHeaders(true).length) {
  //       this.destroy()
  //       this.resetHeaderWidth()
  //       this.$nextTick(() => {
  //         this.buildResizeContainer()
  //         this.bindEvents()
  //       })
  //     }
  //   }
  //   if (!this.disabled) {
  //     requestAnimationFrame(() => {
  //       this.adjustBars()
  //     })
  //   }
  // },
  beforeDestroy() {
    this.destroy()
  },
  methods: {
    emitWidthChange() {
      this.$emit('width-changed', this.columnWidths)
    },
    getTable() {
      if (this.table) {
        return this.table
      }
      if (!this.$refs.container) {
        return null
      }
      const table = this.$refs.container.querySelector('table')
      if (!table) {
        console.error('Please provide table tag inside resizeable table')
        return
      }
      this.table = table
      return this.table
    },
    getThead() {
      if (this.thead) {
        return this.thead
      }
      const table = this.getTable()
      this.thead = table.querySelector('thead')
      return this.thead
    },
    getHeaders(getFresh = false) {
      if (this.th && getFresh === false) {
        return this.th
      }
      const thead = this.getThead()
      let ths = thead.querySelectorAll('th:not(.checkbox):not(.non-resizable)')
      if (ths.length === 0) {
        ths = thead.querySelectorAll('td:not(.checkbox):not(.non-resizable)')
      }
      this.th = null
      this.th = ths
      return this.th
    },
    getBars() {
      if (this.bars) {
        return this.bars
      }
      this.bars = this.$refs.resizeContainer.querySelectorAll(`.resize-handler`)
      return this.bars
    },
    buildResizeContainer() {
      const table = this.getTable()
      const ths = this.getHeaders()
      const resizeContainer = this.$refs.resizeContainer
      table.style.position = 'relative'
      resizeContainer.style.position = 'relative'
      resizeContainer.style.width = table.offsetWidth + 'px'
      // table.parentElement.insertBefore(resizeContainer, table)
      const widths = []
      ths.forEach((th, index) => {
        let newWidth = th.offsetWidth
        if (newWidth <= 80) {
          newWidth = 80
        }
        if (th.style.minWidth && this.cutPx(th.style.minWidth) > newWidth) {
          newWidth = this.cutPx(th.style.minWidth)
        }
        th.style.width = newWidth + 'px'
        widths.push(newWidth)
        if (index + 1 >= ths.length) return

        const nextTh = ths[index + 1]
        const bar = this.buildBar()
        bar.style.left = nextTh.offsetLeft - 4 + 'px'
        bar.setAttribute('data-index', index)
        bar.className = 'resize-handler'
        resizeContainer.appendChild(bar)
      })
      this.$emit('width-changed', widths)
    },
    buildBar() {
      const bar = document.createElement('div')
      return bar
    },
    adjustBars() {
      const bars = this.getBars()
      const ths = this.getHeaders()
      const widths = []
      bars.forEach((bar, index) => {
        // const th = ths[index]
        widths.push(ths[index].offsetWidth)
        const nextTh = ths[index + 1]
        // th.style.width = th.offsetWidth + 'px'
        // th.style.width = th.style.width
        bar.style.left = nextTh.offsetLeft - 4 + 'px'
      })
      this.columnWidths = widths
      this.notifyWidthChangesToParent()
    },
    notifyWidthChangesToParent(calculate = false) {
      if (calculate) {
        const widths = []
        const ths = this.getHeaders()
        ths.forEach((th) => {
          widths.push(th.offsetWidth)
        })
        this.columnWidths = widths
      }
      this.emitWidthChange()
    },
    cutPx(str) {
      return +str.replace('px', '')
    },
    bindEvents() {
      const bars = this.getBars()
      const table = this.getTable()
      bars.forEach((bar, index) => {
        bar.addEventListener('mousedown', this.mouseDown)
      })
      document.addEventListener('mouseup', this.mouseUp)
      this.$refs.resizeContainer.addEventListener('mousemove', this.mouseMove)
      table.addEventListener('mousemove', this.mouseMove)
    },
    unbindEvents() {
      const bars = this.getBars()
      const table = this.getTable()
      bars.forEach((bar, index) => {
        bar.removeEventListener('mousedown', this.mouseDown)
      })
      document.removeEventListener('mouseup', this.mouseUp)
      this.$refs.resizeContainer.removeEventListener(
        'mousemove',
        this.mouseMove
      )
      table.removeEventListener('mousemove', this.mouseMove)
    },
    resetHeaderWidth() {
      const ths = this.getHeaders(true)
      ths.forEach((th, index) => {
        const initialWidth = th.getAttribute('data-initial-width')
        th.style.width = initialWidth
      })
      this.notifyWidthChangesToParent(true)
    },
    destroy() {
      this.unbindEvents()
      this.table = null
      this.bars = null
      this.resizeContainer = null
      this.th = null
      this.thead = null
      this.moving = false
      this.movingIndex = 0
      this.destroyResizeContainer()
    },
    destroyResizeContainer() {
      this.$refs.resizeContainer.innerHTML = ''
    },
    mouseDown(e) {
      this.moving = true
      this.movingIndex = parseInt(e.target.getAttribute('data-index'), 10)
      document.body.style.cursor = 'col-resize'
      document.body.style.userSelect = 'none'
      document.body.style['-webkit-user-select'] = 'none'
    },
    mouseUp() {
      if (!this.moving) return

      this.moving = false
      document.body.style.cursor = ''
      document.body.style.userSelect = ''
      this.$emit('resize-done', this.columnWidths)
      this.adjustBars()
    },
    mouseMove(e) {
      if (this.moving) {
        const ths = this.getHeaders()
        const bars = this.getBars()
        const th = ths[this.movingIndex]
        const nextTh = ths[this.movingIndex + 1]
        const bar = bars[this.movingIndex]
        let newWidth = this.cutPx(th.style.width) + e.movementX
        // minimum 70px is required.
        if (newWidth <= 80) {
          newWidth = 80
        }
        if (th.style.minWidth && this.cutPx(th.style.minWidth) > newWidth) {
          newWidth = this.cutPx(th.style.minWidth)
        }
        th.style.width = newWidth + 'px'
        // nextTh.style.width = cutPx(nextTh.style.width) - e.movementX + 'px'
        bar.style.left = nextTh.offsetLeft - 4 + e.movementX + 'px'
        requestAnimationFrame(() => {
          this.adjustBars()
        })
      }
    },
  },
}
</script>

<style lang="less">
.columns-resizable {
  &.sticky {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .resize-handler {
    position: absolute;
    top: 10px;
    z-index: 1;
    width: 8px;
    height: 15px;
    cursor: col-resize;
    border-left: 1px solid @neutral-light;
  }
}
</style>
