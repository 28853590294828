<template>
  <SortableList
    :value="value"
    :child-key="childKey"
    :disabled="!sortable"
    :class="{
      'sortable-list': level === 1,
      'sortable-list-child': level !== 1,
    }"
    :group-name="`sortable-${level}`"
    :allow-drop="allowDrop"
    ghost-class="test"
    chosen-class="test"
    filter=".not-draggable"
    :level="level"
    v-bind="$attrs"
    @update="handleItemsUpdated"
    @add="handleAddItem"
    @remove="handleRemoveItem"
  >
    <template v-slot:item="{ item, updateItem }">
      <slot
        name="item"
        :item="item"
        :update-children="(children) => handleChildrenChange(children, item)"
        :replace-children="(children) => handleChildrenReplace(children, item)"
        :update-item="updateItem"
        :toggle="() => handleItemExpand(item)"
        :can-add="level < maxLevel"
        :level="level"
        :parent="parent"
      >
        {{ item.name }}
      </slot>
      <NestedSortableList
        v-show="item.expanded && level < maxLevel"
        :value="item.children || []"
        :max-level="maxLevel"
        :sortable="sortable"
        :style="{ paddingLeft: `${levelMargin}px` }"
        :level="level + 1"
        :level-margin="levelMargin"
        :parent="item"
        :update-children="updateChildren"
        :allow-drop="allowDrop"
        v-bind="$attrs"
        @change="handleChildrenChange($event.items, item)"
        v-on="listeners"
      >
        <template v-slot:item="childSlotData">
          <slot
            name="item"
            v-bind="childSlotData"
            :toggle="() => handleItemExpand(childSlotData.item)"
            :update-children="
              (children) => handleChildrenChange(children, childSlotData.item)
            "
            :replace-children="
              (children, passedParent) => {
                handleChildrenReplace(
                  children,
                  passedParent || childSlotData.item
                )
              }
            "
          >
            {{ childSlotData.item.name }}
          </slot>
        </template>
      </NestedSortableList>
    </template>
  </SortableList>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import SortableList from './sortable-list'

export default {
  name: 'NestedSortableList',
  components: { SortableList },
  props: {
    sortable: { type: Boolean, required: true },
    value: { type: Array, required: true },
    childKey: { type: String, default: 'children' },
    maxLevel: { type: Number, default: 3 },
    level: { type: Number, default: 1 },
    levelMargin: { type: Number, default: 50 },
    parent: { type: Object, default: undefined },
    updateChildren: { type: Function, required: true },
    allowDrop: { type: Boolean, default: false },
  },
  computed: {
    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },
  },
  methods: {
    handleItemsUpdated({ items, updatedItems }) {
      this.$emit('change', { items, updatedItems, parent: this.parent })
      this.$emit('move', { items, updatedItems, parent: this.parent })
    },
    handleAddItem(event) {
      const { item, index } = event
      const newData = [
        ...this.value.slice(0, index),
        item,
        ...this.value.slice(index),
      ]
      // @TODO calculate only itesm which are changed
      this.$emit('change', { items: newData, parent: this.parent })
      this.$emit('add', event, this.parent)
    },
    handleRemoveItem(event) {
      const { item } = event
      const newData = this.value.filter((i) => i.guid !== item.guid)
      // @TODO calculate only items which are changed
      this.$emit('change', { items: newData, parent: this.parent })
      this.$emit('remove', event, this.parent)
    },
    handleChildrenReplace(children, parent) {
      parent.expanded = true
      parent.children = children
      this.$forceUpdate()
    },
    handleChildrenChange(children, parent) {
      // @TODO calculate difference of children for update
      const currentChildren = CloneDeep(parent.children)
      this.handleChildrenReplace(children, parent)
      this.updateChildren(children, parent).catch(() => {
        this.handleChildrenReplace(currentChildren, parent)
      })
    },
    handleItemExpand(item) {
      item.expanded = !item.expanded
      this.$forceUpdate()
    },
  },
}
</script>
