export function getValidationRulesForEstimatedTime(unit) {
  switch (unit) {
    case 'minutes':
      return { min_value: 15, max_value: 525600 }
    case 'hours':
      return { min_value: 1, max_value: 24 }
    case 'days':
      return { min_value: 1, max_value: 365 }
    case 'months':
      return { min_value: 1, max_value: 12 }
  }
  return {}
}
